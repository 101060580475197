import React, { useState, useEffect, createRef } from 'react';
import { Col, Row, Container, FormGroup, CustomInput, Button, Card, CardBody, Badge, Table, Label, Input, Collapse, Alert } from "reactstrap";
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import axios from 'axios';

import './App.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';



function App() {

  const [selectedFile, setSelectedFile] = useState(null);
  const [gcpGeojson, setGcpGeojson] = useState(null);
  const [gcpCsv, setGcpCsv] = useState(null);

  const [gcpNumber, setGcpNumber] = useState(null);
  const [altitudeColumn, setAltitudeColumn] = useState("altitude");

  const [gcpTable, setGcpTable] = useState(null);

  const [geojsonPreviewOpen, setGeojsonPreviewOpen] = useState(false);
  const [guidelinesVisible, setGuidelinesVisible] = useState(true);

  const [badgeInfo, setBadgeInfo] = useState(null);
  const [badgeGcp, setBadgeGcp] = useState(null);
  const [badgeAltitude, setBadgeAltitude] = useState(null);
  const [selectColumnInput, setSelectColumnInput] = useState(null);

  //Ref to the leaflet Map component
  const map = createRef()
  //Ref to the leaflet features layer component
  const geojson_layer = createRef()


  useEffect(() => {
    if (gcpGeojson) {
      map.current.leafletElement.fitBounds(
        geojson_layer.current.leafletElement.getBounds()
      );
    }

    // manage gcp table view
    let gcpFeatures = gcpGeojson ? gcpGeojson.features : []
    let rows = []
    let gcpNumber = gcpFeatures.length
    setGcpNumber(gcpNumber)
    gcpFeatures.forEach(gcp =>
      rows.push(
        <tr key={gcp.properties.nom}>
          <td>{gcp.properties.nom}</td>
          <td>{gcp.geometry.coordinates[0]}</td>
          <td>{gcp.geometry.coordinates[1]}</td>
          <td>{gcp.geometry.coordinates[2] ? gcp.geometry.coordinates[2] : gcp.properties[altitudeColumn]}</td>
        </tr>
      )
    )

    let gcpTable = <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Altitude</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>

    setGcpTable(gcpTable)

    // check altitude
    let gcpAltitudes = gcpFeatures.filter(gcp => (gcp.properties.altitude && gcp.properties.altitude !== 0) || (gcp.geometry.coordinates[2] && gcp.geometry.coordinates[2] !== 0))
    let altitudeFound = (gcpAltitudes.length === 0) ? false : true

    // manage quality checks badges
    let badgeInfo = null
    let badgeGcp = null
    let badgeAltitude = null
    let selectColumnInput = null

    if (gcpGeojson === null) {
      badgeInfo = <h4><p><Badge color="info"> Please upload a gcp file</Badge></p></h4>
      setBadgeInfo(badgeInfo)
    }
    else {
      if (gcpNumber === 0) {
        badgeGcp = <h4><p><Badge color="danger">x</Badge> No GCP found </p></h4>
      }
      else {
        badgeGcp = <h4><p><Badge color="success">v</Badge> {gcpNumber} GCP found</p></h4>
      }
      setBadgeGcp(badgeGcp)
      setBadgeInfo(null)

      if (altitudeFound === false && altitudeColumn === "altitude") {
        badgeAltitude = <>
          <h4><p><Badge color="danger">x</Badge> No altitude found </p></h4>
        </>
      }
      else if (altitudeColumn !== "altitude") {
        badgeAltitude = <h4><p><Badge color="warning">~</Badge> non standard altitude column selected</p></h4>
      }
      else {
        badgeAltitude = <h4><p><Badge color="success">v</Badge> Altitude found</p></h4>
      }
      setBadgeAltitude(badgeAltitude)

      if (altitudeFound === false || altitudeColumn !== "altitude") {
        let propertiesColumns = Object.keys(gcpGeojson.features[0].properties)
        let optionsColumns = []
        propertiesColumns.map(column =>
          optionsColumns.push(
            <option>{column}</option>
          )
        )

        selectColumnInput = <>
          <Label for="selectAltitude">Is altitude present in one of those columns ?</Label>
          <Input
            type="select" id="selectAltitude"
            onChange={
              (e) => {
                setAltitudeColumn(e.target.value);
              }
            }
          >
            <option disabled selected value> -- select a column -- </option>
            {optionsColumns}
          </Input>
        </>

      }
      setSelectColumnInput(selectColumnInput)
    }

    if (gcpGeojson) {
      // update altitude properties
      if (altitudeColumn !== "altitude") {
        gcpGeojson.features.forEach(feature => feature.properties.altitude = feature.properties[altitudeColumn])
      }
      else if (altitudeFound) {
        gcpGeojson.features.forEach(feature => feature.properties.altitude = feature.geometry.coordinates[2])
      }

      // update csv
      if (gcpNumber > 0 && (altitudeFound || setAltitudeColumn !== "altitude")) {
        axios.post(
          "/api/to-csv", gcpGeojson
        ).then((response) => {
          setGcpCsv(response.data.gcpCsv)
        })
      }
    }


    // eslint-disable-next-line
  }, [gcpGeojson, altitudeColumn]);


  return (
    <div className="App">
      <Container fluid>

        <Row>
          <Col xl="6">
            <br></br>
            <Alert color="info" isOpen={guidelinesVisible} toggle={() => setGuidelinesVisible(false)} >
              This tool helps you to check quality of your <a href="https://www.groundcontrolpoints.com/mapping-contour-lines-using-gcps" className="alert-link">GCP files</a> and transform it in a standard format.
              <ol>
                <li>Upload your gcp file in any standard geographical format (shapefile, kml, geopackage ...). For shapefiles, please Zip them in a unique folder.</li>
                <li>Quality checks will tell you whether your file is ok or not.</li>
                <ul>
                  <li>Latitude/ Longitude stored with a ref to a specific <a href="https://en.wikipedia.org/wiki/Spatial_reference_system" className="alert-link">Coordinate reference system</a></li>
                  <li>Altitude stored in a standard way, either as a third coordinate (x,y,z) or in an "altitude" column.</li>
                </ul>

                <li>If you get a warning about altitude, please select a column that contains altitude and check the "GCP table preview" to be sure of your altitude</li>
                <li> Export your GCP as a geojson, a modern and easy-to-use geographical file format.</li>
              </ol>

            </Alert>

            <br></br>
            <FormGroup>
              <CustomInput
                id="input-gcp"
                type="file" label="Pick a gcp"
                color="primary"
                // accept=".geojson"
                required
                onChange={
                  (e) => {
                    setSelectedFile(e.target.files[0]);
                  }
                }
              />
            </FormGroup>

            <Button disabled={selectedFile ? false : true} onClick={
              () => {
                let formData = new FormData()
                formData.append('file', selectedFile)
                // console.log(selectedFile)
                // console.table([...formData])
                axios.post("/api/convert", formData, { 'Content-Type': 'multipart/form-data' })
                  .then((response) => {
                    setGcpGeojson(JSON.parse(response.data.gcpGeojson))
                  })
                  .catch((error) => {
                    alert(error.response.data)
                  })
              }
            }>upload</Button>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <Card>
              <CardBody>
                <p>Quality checks</p>
                {badgeInfo}
                {badgeGcp}
                {badgeAltitude}
                {selectColumnInput}
              </CardBody>
            </Card>
            <br></br>

            {gcpNumber > 0 &&
              <>
                <p>Download gcp</p>
                < Button
                  outline
                  color="success"
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(
                      gcpGeojson
                    )
                  )}`}
                  download="gcp.geojson"
                >
                  Geojson
                </Button>
              </>
            }
            {gcpCsv &&
              < Button
                outline
                color="success"
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                  gcpCsv
                )}`}
                download="GCP.csv"
              >
                CSV
              </Button>
            }

            <br></br>
            <br></br>

            {gcpGeojson &&
              <>
                <Card>
                  <CardBody>
                    <p>Geojson preview</p>
                    <Button color="info" onClick={() => setGeojsonPreviewOpen(!geojsonPreviewOpen)}>display</Button>
                    <Collapse isOpen={geojsonPreviewOpen}>
                      <pre>{JSON.stringify(gcpGeojson, null, 2)}</pre>
                    </Collapse>
                  </CardBody>
                </Card>
              </>
            }

          </Col>

          <Col xl="6">
            <br></br>
            <Card>
              <CardBody>
                <p>GCP Map preview</p>
                <Map
                  center={[43.91631188865807, 4.893100928364243]}
                  zoom={2}
                  ref={map}
                  maxZoom={30}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  {gcpGeojson &&
                    <GeoJSON ref={geojson_layer} data={gcpGeojson} />
                  }

                </Map>
              </CardBody>

            </Card>

            <Card>
              <CardBody>
                <p>GCP table preview</p>
                {gcpTable}
              </CardBody>
            </Card>

          </Col>
        </Row>

      </Container>

    </div >
  );
}

export default App;
